import React, { createContext, useState } from 'react';
import firebase from '../Firebase';

export const ProfilesContext = createContext();

function ProfilesContextProvider(props) {
  const [profiles, setProfiles] = useState(null);
  const [profilesAccess, setProfilesAccess] = useState([]);

  const getProfiles = async () => {
    try {
      const user = firebase.auth().currentUser;
      const _profiles = (
        await firebase
          .firestore()
          .collection('profiles')
          .where('createdBy', '==', user.uid)
          .get()
      ).docs;
      const snapshot = await firebase
        .firestore()
        .collection('profileAccess')
        .where('grantedTo', '==', user.uid)
        .get();
      const profilesAccess = [];
      snapshot.forEach((doc) => {
        if (doc.data().status === 'accepted') {
          profilesAccess.push(doc);
        }
      });
      setProfilesAccess(profilesAccess);
      // go over profilesAccess and fetch the profiles using the profileId
      for (let index = 0; index < profilesAccess.length; index++) {
        const profile = profilesAccess[index];
        const profileId = profile.data().profileId;
        const profileDoc = await firebase
          .firestore()
          .collection('profiles')
          .doc(profileId)
          .get();
        _profiles.push(profileDoc);
      }
      setProfiles(_profiles ?? []);
    } catch (error) {
      setProfiles([]);
      console.error('Error fetching profiles', error);
    }
  };

  return (
    <ProfilesContext.Provider
      value={{
        profiles,
        setProfiles,
        getProfiles,
        profilesAccess,
      }}
    >
      {props.children}
    </ProfilesContext.Provider>
  );
}

export default ProfilesContextProvider;
