import { Grid, Stack, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { ASSESSMENTS_IDS, EVENTS } from '../components/AddOrEditEventDialog';
import AnalyticsCard from '../components/analytics/Card';
import JournalSummaryCard from '../components/analytics/JournalSummaryCard';
import Stats from '../components/analytics/Stats';
import { EventsContext } from '../contexts/EventsContext';
import { ProfileContext } from '../contexts/Profile';
import { WalkthroughContext } from '../contexts/Walkthrough';
import useResponsive from '../hooks/useResponsive';

const CONTAINER = {
  backgroundColor: '#FDF4F3',
  padding: 18,
  minHeight: '100vh',
};

const Analytics = (props) => {
  const { events } = useContext(EventsContext);
  const { customEvents } = useContext(ProfileContext);
  const { run } = useContext(WalkthroughContext);
  const isDesktop = useResponsive('up', 'md');

  const getEventById = (id) => {
    return [...EVENTS, ...customEvents].find((e) => e.id === id);
  };

  const findMostUsedEmojis = () => {
    if (run) {
      return [
        { title: '😞', color: '#8863BA', value: 2, maxValue: 13 },
        { title: '🏥', color: '#F9DFDB', value: 5, maxValue: 13 },
        { title: '💊', color: '#B8A1D6', value: 6, maxValue: 13 },
      ];
    }
    // Step 1: Flatten the array of JSONs and extract emojis
    const allEmojis = events
      .flatMap((item) => item.data().emoji)
      .filter((item) => item !== undefined);

    // Step 2: Count the occurrences of each emoji
    const emojiCountMap = allEmojis.reduce((countMap, emoji) => {
      countMap[emoji] = (countMap[emoji] || 0) + 1;
      return countMap;
    }, {});

    // Step 3: Convert the map to an array of { emoji, count } objects
    const emojiCountArray = Object.entries(emojiCountMap).map(
      ([emoji, count]) => ({
        emoji,
        count,
      }),
    );

    // Step 4: Sort the array by count in descending order
    emojiCountArray.sort((a, b) => b.count - a.count);

    // Step 5: Take the top 3 emojis
    const top3Emojis = emojiCountArray.slice(0, 3).map((item) => ({
      title: item.emoji,
      value: item.count,
      color: '#0C3954',
      maxValue: events.length,
    }));

    return top3Emojis;
  };

  const findMostUsedTags = () => {
    if (run) {
      return [
        { title: 'Hospital Visit', color: '#8863BA', value: 2, maxValue: 13 },
        { title: 'Mood', color: '#F9DFDB', value: 5, maxValue: 13 },
        { title: 'Medications', color: '#B8A1D6', value: 6, maxValue: 13 },
      ];
    }
    // Step 1: Flatten the array of JSONs and extract events
    const allEvents = events
      .flatMap((item) => item.data().events)
      .filter((item) => item !== undefined);

    // Step 2: Count the occurrences of each emojeventi
    const eventCountMap = allEvents.reduce((countMap, event) => {
      countMap[event] = (countMap[event] || 0) + 1;
      return countMap;
    }, {});

    // Step 3: Convert the map to an array of { event, count } objects
    const eventCountArray = Object.entries(eventCountMap).map(
      ([id, count]) => ({
        id,
        count,
      }),
    );

    // Step 4: Sort the array by count in descending order
    eventCountArray.sort((a, b) => b.count - a.count);

    // Step 5: Take the top 3 emojis
    const top3Events = eventCountArray.slice(0, 3).map((item) => ({
      title: getEventById(item.id)?.name || '',
      value: item.count,
      color: getEventById(item.id)?.color || '#0C3954',
      maxValue: allEvents.length,
    }));

    return top3Events;
  };

  return (
    <div
      style={{
        ...CONTAINER,
        backgroundColor: events.length >= 3 ? '#FDF4F3' : 'white',
      }}
    >
      {events.length === 0 && !run && (
        <Stack
          direction={'column'}
          spacing={4}
          sx={{ width: '100%' }}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Typography sx={{ fontWeight: 700, fontSize: 24 }}>
            Insights Not Available
          </Typography>
          <img
            alt=""
            src={require('../assets/images/notEnougthAsset.png')}
            style={{ width: 300, height: 300, objectFit: 'contain' }}
          />
          <Typography sx={{ fontWeight: 500, fontSize: 18, maxWidth: 300 }}>
            You didn’t journal enough during this time to see insights
          </Typography>
        </Stack>
      )}
      {events.length > 0 && events.length < 3 && !run && (
        <>
          <Stack
            direction={'column'}
            spacing={4}
            sx={{ width: '100%' }}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Typography sx={{ fontWeight: 700, fontSize: 24 }}>
              Journal More to Unlock Insights
            </Typography>
            <img
              alt=""
              src={require('../assets/images/chartAsset.png')}
              style={{ width: 300, height: 300, objectFit: 'contain' }}
            />
            <Typography sx={{ fontWeight: 500, fontSize: 18, maxWidth: 300 }}>
              {`Journal ${
                3 - events.length
              } more times to start seeing your insights`}
            </Typography>
          </Stack>
        </>
      )}
      {(events.length >= 3 || run) && (
        <Grid spacing={3} container>
          <Grid item xs={12} md={6}>
            <Stats />
          </Grid>
          <Grid item xs={12} md={6}>
            <JournalSummaryCard />
          </Grid>
          <Grid item xs={12} md={4}>
            <AnalyticsCard
              title={'Tags Used Most'}
              items={findMostUsedTags()}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <AnalyticsCard
              title={'Emojis Used Most'}
              items={findMostUsedEmojis()}
            />
          </Grid>
          {run ? (
            <Grid item xs={12} md={4}>
              <AnalyticsCard
                title={'Surveys Used Most'}
                items={[
                  {
                    title: 'Emotions',
                    color: '#FEF1AB',
                    value: 4,
                    maxValue: 7,
                  },
                  {
                    title: 'Fatigue',
                    color: '#CFF5E3',
                    value: 9,
                    maxValue: 12,
                  },
                ]}
              />
            </Grid>
          ) : (
            <Grid item xs={12} md={4}>
              <AnalyticsCard
                title={'Surveys Used Most'}
                items={[
                  {
                    title: 'Emotions',
                    color: '#FEF1AB',
                    value: events.filter((e) => e.data()?.formId === 'C7NnGZyA')
                      .length,
                    maxValue: events.filter((e) =>
                      ASSESSMENTS_IDS.includes(e.data()?.formId),
                    ).length,
                  },
                  {
                    title: 'Fatigue',
                    color: '#CFF5E3',
                    value: events.filter((e) => e.data()?.formId === 'FMkEt9ok')
                      .length,
                    maxValue: events.filter((e) =>
                      ASSESSMENTS_IDS.includes(e.data()?.formId),
                    ).length,
                  },
                ]}
              />
            </Grid>
          )}
        </Grid>
      )}
    </div>
  );
};

export default Analytics;
