import { Close, CloseRounded } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  Dialog,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import axios from 'axios';
import Lottie from 'lottie-react';
import moment from 'moment-timezone';
import React, { useContext, useState } from 'react';
import { EventsContext } from '../../contexts/EventsContext';
import { ProfileContext } from '../../contexts/Profile';

const CARD = {
  p: 3,
  bgcolor: '#0C3954',
  position: 'relative',
  borderRadius: 3,
};

const STACK = {
  width: '100%',
};

const TITLE = {
  fontSize: 16,
  fontWeight: 600,
  color: 'white',
  textAlign: 'start',
};

const SUBTITLE = {
  fontSize: 16,
  color: 'white',
  textAlign: 'start',
  pr: 8,
};

const BUTTON = {
  color: 'white',
  bgcolor: '#69B3E3',
  borderRadius: 2,
};

const BG = { position: 'absolute', top: 0, right: 0, width: 100 };

const DIALOG = {
  'p': 3,
  'bgcolor': '#0C3954',
  'boxShadow': 'none',
  '.MuiDialog-paperFullScreen': {
    bgcolor: '#081c2a',
    boxShadow: 'none',
  },
};

const DIALOG_TITLE = {
  color: 'white',
  fontSize: 24,
  fontWeight: 600,
};

const DIALOG_SUBTITLE = {
  color: 'white',
  fontSize: 18,
  fontWeight: 500,
};

const DIALOG_CONTENT = {
  color: 'white',
  fontSize: 16,
};

const JournalSummaryCard = () => {
  const { currentProfile } = useContext(ProfileContext);
  const { events } = useContext(EventsContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [summary, setSummary] = useState(null);
  const [loadingSummary, setLoadingSummary] = useState(false);

  const [dots, setDots] = React.useState(1);

  React.useEffect(() => {
    if (loadingSummary) {
      const intervalId = setInterval(() => {
        setDots((prevDots) => (prevDots % 3) + 1);
      }, 300);

      return () => clearInterval(intervalId);
    }
  }, [loadingSummary]);

  const getSummary = async () => {
    setLoadingSummary(true);
    const content = events.map(
      (e) =>
        `Report: ${e.data().description} Report events: ${e
          .data()
          .events?.join()}`,
    );
    const res = await axios.post(
      'https://us-central1-knowrare-platform-e2cc9.cloudfunctions.net/getJournalSummary',
      {
        text: content.join(' '),
      },
      {
        headers: {
          'Accept': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
        },
      },
    );
    setLoadingSummary(false);
    if (res.data?.choices?.length > 0) {
      setSummary(
        `Hi ${
          currentProfile?.data().name.split(' ')[0]
        }, here is a short report about how your journal entries have been affecting you. \n\n ${
          res.data.choices[0].message.content
        }`,
      );
      setOpenDialog(true);
    } else {
      alert('Error');
    }
  };

  return (
    <>
      <Dialog fullScreen sx={DIALOG} open={openDialog}>
        <Stack direction={'column'} spacing={2}>
          <Stack direction={'row'} spacing={2} justifyContent={'end'}>
            <IconButton
              onClick={() => {
                setOpenDialog(false);
                setSummary(null);
              }}
            >
              <CloseRounded sx={{ color: 'white' }} />
            </IconButton>
          </Stack>
          <Typography sx={DIALOG_TITLE}>AI Journal Summary</Typography>
          <Typography sx={DIALOG_SUBTITLE} variant="h1">
            {moment(
              events[events.length - 1]?.data().submittedAt.seconds * 1000,
            ).format('MMM YYYY ')}
            -
            {moment(events[0]?.data().submittedAt.seconds * 1000).format(
              ' MMM YYYY',
            )}
          </Typography>
          {summary?.split('\n').map((line, index) => (
            <Typography key={index} sx={DIALOG_CONTENT}>
              {line}
            </Typography>
          ))}
        </Stack>
      </Dialog>
      <Card sx={CARD}>
        <img
          alt=""
          src={require('../../assets/images/journalImageBackground.png')}
          style={BG}
        />
        <Stack spacing={3} sx={STACK}>
          <Typography sx={TITLE}>AI Journal Summary</Typography>
          <Typography sx={SUBTITLE}>
            See your personalized journal summary that’s easy to read,
            understand and share.
          </Typography>
          <LoadingButton
            variant="contained"
            size="large"
            sx={BUTTON}
            onClick={getSummary}
            loading={loadingSummary}
          >
            View Report
          </LoadingButton>
        </Stack>
        {loadingSummary && (
          <Stack
            direction={'column'}
            gap={1.5}
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              position: 'fixed',
              width: '100vw',
              height: '100vh',
              background: 'white',
              zIndex: 999999999,
              top: 0,
              left: 0,
            }}
          >
            <IconButton
              aria-label="close"
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'black',
              }}
              onClick={() => {
                setLoadingSummary(false);
                setOpenDialog(false);
              }}
            >
              <Close style={{ width: 24, height: 24 }} />
            </IconButton>
            <Box
              sx={{
                width: 150,
                height: 50,
                fontSize: 24,
                color: 'black',
                fontWeight: 500,
              }}
            >{`Creating AI Report${'.'.repeat(dots)}`}</Box>
            <Lottie
              animationData={require('../../assets/lotties/analyzing.json')}
              style={{
                height: 160,
                width: 160,
              }}
              loop={true}
            />
          </Stack>
        )}
      </Card>
    </>
  );
};

export default JournalSummaryCard;
