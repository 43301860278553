import { CircularProgress, Container } from '@mui/material';
import React from 'react';

const BACKGROUND = {
  width: '100vw',
  height: '100vh',
  bgcolor: 'white',
  position: 'fixed',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  top: 0,
  left: 0,
  zIndex: 9999999,
};

const Loader = () => {
  return (
    <Container sx={BACKGROUND} maxWidth={false} disableGutters>
      <CircularProgress />
    </Container>
  );
};

export default Loader;
