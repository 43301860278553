import { ArrowDropDown } from '@mui/icons-material';
import {
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import Badges from '../components/Badges';
import EditMedicationsDialog from '../components/EditMedicationsDialog';
import ProfileItem from '../components/ProfileItem';
import { ProfileContext } from '../contexts/Profile';
import { ProfilesContext } from '../contexts/Profiles';
import useResponsive from '../hooks/useResponsive';

const Profile = () => {
  const navigate = useNavigate();
  const isDesktop = useResponsive('up', 'md');
  const { profiles } = useContext(ProfilesContext);
  const { currentProfile, level, setCurrentProfile, role } =
    useContext(ProfileContext);

  const [openMedicationsDialog, setOpenMedicationsDialog] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSwitchProfile = (profile) => () => {
    setAnchorEl(null);
    setCurrentProfile(profile);
  };

  return (
    <Container
      disableGutters={true}
      maxWidth={'sm'}
      sx={{
        mb: 3,
        mt: isDesktop ? 1 : 4,
        bgcolor: isDesktop ? 'white' : '#FDF4F3',
        pb: 3,
        minHeight: '100vh',
      }}
    >
      <EditMedicationsDialog
        open={openMedicationsDialog}
        setOpen={setOpenMedicationsDialog}
      />
      <Stack
        direction={'column'}
        justifyContent={'center'}
        alignItems={'start'}
        gap={3}
        sx={{
          bgcolor: 'white',
          borderBottomLeftRadius: 32,
          borderBottomRightRadius: 32,
          pl: 3,
          pr: 3,
          pb: 3,
        }}
      >
        <Stack direction={'row'} spacing={2} alignItems={'center'}>
          <img
            alt=""
            src={require('../assets/images/profileIcon.png')}
            style={{ width: 70, height: 70 }}
          />
          <Stack
            direction={'row'}
            justifyContent={'center'}
            alignItems={'center'}
            gap={1}
          >
            <Typography
              sx={{ fontSize: 22, color: '#0C3954', fontWeight: 600 }}
            >
              {currentProfile?.data().name}
            </Typography>
            <IconButton onClick={handleClick}>
              <ArrowDropDown />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              {profiles.map((profile) => (
                <MenuItem
                  key={profile.id}
                  onClick={handleSwitchProfile(profile)}
                >
                  {profile.data().name}
                </MenuItem>
              ))}
            </Menu>
          </Stack>
        </Stack>
        <Stack
          direction={'row'}
          justifyContent={'center'}
          alignItems={'center'}
          gap={1}
          sx={{ width: '100%' }}
        >
          <Button
            sx={{
              bgcolor: '#E6E6E6',
              flexGrow: 1,
              borderRadius: 99,
              color: 'black',
              fontWeight: 400,
            }}
            size="small"
            onClick={() => navigate('/dashboard/settings')}
          >
            Settings
          </Button>
          <Button
            sx={{
              bgcolor: '#E6E6E6',
              flexGrow: 1,
              borderRadius: 99,
              color: 'black',
              fontWeight: 400,
            }}
            size="small"
            onClick={() => navigate('/dashboard/share-profile')}
          >
            Share Profile{' '}
          </Button>
        </Stack>
      </Stack>
      <Badges level={level} />
      <ProfileItem
        title={'Medications'}
        icon={'medications'}
        onClick={() => setOpenMedicationsDialog(true)}
      />
    </Container>
  );
};

export default Profile;
