import mixpanel from 'mixpanel-browser';
import React, { createContext, useState } from 'react';
import firebase from '../Firebase';

export const AuthContext = createContext();

function AuthContextProvider(props) {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [currentUser, setCurrentUser] = useState(false);

  const [loading, setLoading] = useState(null);

  const reset = () => {
    setCurrentUser(null);
    setIsAuthenticated(false);
  };

  const logout = () => {
    reset();
    firebase.auth().signOut();
    mixpanel.reset();
    window.zE('messenger', 'logoutUser');
  };

  const resendValidationEmail = async () => {
    const res = await firebase.auth().currentUser.sendEmailVerification();
    return res;
  };

  const signInAnonymously = () => {
    firebase
      .auth()
      .signInAnonymously()
      .then((res) => {
        // Signed in..
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log('error', error);
        // ...
      });
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        currentUser,
        setCurrentUser,
        loading,
        setIsAuthenticated,
        logout,
        resendValidationEmail,
        signInAnonymously,
        setLoading,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

export default AuthContextProvider;
