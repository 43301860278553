import {
  AccessibleForwardRounded,
  AddRounded,
  HelpOutline,
} from '@mui/icons-material';
import { Box, Button, IconButton, Stack } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { AuthContext } from '../contexts/Auth';
import { EventsContext } from '../contexts/EventsContext';
import { IframeContext } from '../contexts/IframeContext';
import { WalkthroughContext } from '../contexts/Walkthrough';
import useResponsive from '../hooks/useResponsive';
import { UnhideOnScroll } from '../screens/Home';
import { track } from '../utils/analytics';
import SelectLanguage from './SelectLanguage';
const pages = ['Products', 'Pricing', 'Blog'];
const settings = ['Edit Profile', 'Legal', 'Logout'];

function DashboardAppBar({
  withLogo = false,
  setOpenDrawer,
  getPageHeaderButton,
}) {
  const { logout } = React.useContext(AuthContext);
  const { startTour } = React.useContext(WalkthroughContext);
  const { setOpenFilterDialog, openEventDialog } =
    React.useContext(EventsContext);
  const { setUrl } = React.useContext(IframeContext);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const location = useLocation();
  const isDesktop = useResponsive('up', 'md');

  const navigate = useNavigate();
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const onMenuSelect = (action) => {
    if (action === 'Logout') {
      track('menu_logout_button_click');
      logout();
    } else if (action === 'Legal') {
      track('menu_legal_button_click');
      setUrl('https://knowrare.com/legal-app');
      // window.open('https://knowrare.com/terms', '_blank');
    } else if (action === 'Edit Profile') {
      track('menu_edit_profile_button_click');
      navigate('/dashboard/profile');
    }
    handleCloseUserMenu();
  };

  const getPageTitle = () => {
    if (location.pathname.includes('home')) return 'Journal';
    if (location.pathname.includes('learn')) return 'Learn';
    if (location.pathname.includes('journal')) return 'Journal';
    if (location.pathname.includes('profile')) return 'Edit Profile';
    if (location.pathname.includes('chat')) return 'Chat';
    if (location.pathname.includes('surveys')) return 'Surveys';
    if (location.pathname.includes('analytics')) return 'Analytics';
    return 0;
  };

  const getPageColor = () => {
    if (location.pathname.includes('home')) return '#FFF';
    if (location.pathname.includes('learn')) return '#FFF';
    if (location.pathname.includes('journal')) return '#FFF';
    if (location.pathname.includes('chat')) return '#FFF';
    if (location.pathname.includes('profile')) return '#FFF';
    if (location.pathname.includes('surveys')) return '#FFF';
    if (location.pathname.includes('analytics')) return '#FCF4F3';
    return 0;
  };

  const getAppBarShadow = () => {
    if (location.pathname.includes('analytics')) return '0px 24px 32px #FCF4F3';
    return '0px 24px 32px #fff';
  };

  return (
    <Stack direction={'column'}>
      <AppBar
        position="fixed"
        sx={{
          bgcolor: getPageColor(),
          zIndex: (theme) => theme.zIndex.drawer + 0,
          boxShadow: getAppBarShadow(),
        }}
        elevation={0}
      >
        <Container maxWidth={false} disableGutters={!isDesktop}>
          {!isDesktop ? (
            <Toolbar
              disableGutters={isDesktop}
              sx={{
                // bgcolor: '#FFF',
                bgcolor: getPageColor(),
                width: isDesktop ? 'calc(100% - 248px)' : '100%',
              }}
              style={{
                marginLeft: isDesktop ? 248 : 0,
              }}
            >
              <Stack
                direction={'row'}
                spacing={1}
                justifyContent={'space-between'}
                sx={{ width: '100%' }}
              >
                <IconButton
                  onClick={() => {
                    navigate('/dashboard/home');
                    startTour();
                  }}
                >
                  <HelpOutline />
                </IconButton>
                <IconButton
                  LinkComponent={'a'}
                  href="#"
                  data-acsb-custom-trigger="true"
                >
                  <AccessibleForwardRounded />
                </IconButton>
                {/* <IconButton onClick={handleOpenUserMenu}>
                  <SettingsOutlined sx={{ color: 'black' }} />
                </IconButton> */}
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem
                      key={setting}
                      onClick={() => onMenuSelect(setting)}
                    >
                      <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                  ))}
                  <MenuItem onClick={() => onMenuSelect(setting)}>
                    <SelectLanguage />
                  </MenuItem>
                </Menu>
                <UnhideOnScroll>
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{
                      borderRadius: 3,
                      width: 120,
                    }}
                    onClick={() => setOpenFilterDialog(true)}
                  >
                    Filter
                  </Button>
                </UnhideOnScroll>
              </Stack>
            </Toolbar>
          ) : (
            <Toolbar
              disableGutters={false}
              sx={{
                bgcolor: 'white',
                width: isDesktop ? 'calc(100% - 180px)' : '100%',
              }}
              style={{
                marginLeft: isDesktop ? 212 : 0,
              }}
            >
              <Stack
                direction={'row'}
                justifyContent={'start'}
                alignItems={'center'}
                sx={{ bgcolor: 'white', width: '100%' }}
                spacing={2}
              >
                <Typography
                  sx={{ color: 'black', ms: 2, fontSize: 24, fontWeight: 500 }}
                >
                  {getPageTitle()}
                </Typography>
                <Box flex={1} />
                <UnhideOnScroll>
                  <Button
                    variant="outlined"
                    size="regular"
                    sx={{
                      borderRadius: 3,
                      width: 120,
                    }}
                    onClick={() => setOpenFilterDialog(true)}
                  >
                    Filter
                  </Button>
                  <IconButton
                    sx={{ bgcolor: 'primary.main', color: 'white' }}
                    onClick={() => openEventDialog()}
                  >
                    <AddRounded />
                  </IconButton>
                </UnhideOnScroll>
              </Stack>
            </Toolbar>
          )}
        </Container>
      </AppBar>
    </Stack>
  );
}
export default DashboardAppBar;
