import {
  Box,
  Container,
  Divider,
  Slide,
  Stack,
  Typography,
  useScrollTrigger,
} from '@mui/material';
import Lottie from 'lottie-react';
import moment from 'moment-timezone';
import React, { useContext, useEffect } from 'react';
import CustomizedDialogs from '../components/Dialog';
import JournalItems from '../components/JournalItems';
import { AuthContext } from '../contexts/Auth';
import { EventsContext } from '../contexts/EventsContext';
import { ProfileContext } from '../contexts/Profile';
import useResponsive from '../hooks/useResponsive';

export function ShowOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: window ? window.screen.height - 100 : 400,
  });

  return (
    <Slide appear={false} direction="up" in={trigger}>
      {children}
    </Slide>
  );
}

export function UnhideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: window ? window.screen.height - 100 : 400,
  });

  return trigger ? children : <></>;
}

const Home = (props) => {
  useEffect(() => {}, []);
  const { showSuccessAndContactDialog, setShowSuccessAndContactDialog } =
    useContext(AuthContext);
  const { currentProfile, role } = useContext(ProfileContext);
  const { events, selectedEvents, setEventDialogIsOpen, openEventDialog } =
    useContext(EventsContext);
  const isDesktop = useResponsive('up', 'md');

  const countEventsFromStartOfMonth = () => {
    if (!events) return '-';
    // Get the current date
    const currentDate = moment();

    // Get the start of the current month
    const startOfMonth = currentDate.startOf('month');

    // Filter events created from the start of the current month
    const eventsFromStartOfMonth = events.filter((event) => {
      const eventDate = moment(event.data().submittedAt.seconds * 1000);
      return eventDate.isSameOrAfter(startOfMonth);
    });

    // Return the count of events
    return eventsFromStartOfMonth.length;
  };

  return (
    <Container disableGutters maxWidth={false} sx={{ mb: 3 }}>
      <Stack
        direction={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        spacing={3}
        sx={{
          pt: isDesktop ? 0 : role !== 'viewer' ? 3 : 10,
          borderRadius: 16,
        }}
      >
        {role !== 'viewer' && (
          <Typography
            sx={{
              color: 'black',
              fontSize: 24,
              fontWeight: 600,
              textAlign: 'center',
              width: '100%',
              marginTop: 72,
              pl: 8,
              pr: 8,
            }}
          >{`Hi ${
            currentProfile?.data().name.split(' ')[0]
          }, what's on your mind?`}</Typography>
        )}
        {role !== 'viewer' && (
          <Box
            sx={{
              width: '100%',
              maxWidth: 500,
              position: 'relative',
              cursor: 'pointer',
            }}
            onClick={() => openEventDialog()}
          >
            <Lottie
              animationData={require('../assets/lotties/addBackground.json')}
              loop={true}
              id="step-1"
            />
            <Box
              sx={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              <Lottie
                animationData={require('../assets/lotties/loadingBlueButton.json')}
                loop={true}
                width={60}
                height={60}
                style={{ width: 60, height: 60 }}
              />
            </Box>
          </Box>
        )}
        <Box sx={{ width: '100%', pl: 3, pr: 3, pb: 3, maxWidth: 400 }}>
          <Stack
            direction={'row'}
            sx={{
              bgcolor: '#CFF5E3',
              p: 1,
              borderRadius: 34,
              mt: -6,
              zIndex: 0,
              position: 'relative',
            }}
            justifyContent={'space-around'}
          >
            <Stack
              direction={'row'}
              justifyContent={'center'}
              alignItems={'center'}
              sx={{ mr: -5 }}
              spacing={1}
            >
              <Typography
                sx={{ fontWeight: 600, fontSize: 36, color: '#0C3954' }}
              >
                {countEventsFromStartOfMonth()}
              </Typography>
              <Stack direction={'column'} alignItems={'start'} sx={{}}>
                <Typography
                  sx={{ fontWeight: 600, fontSize: 16, color: '#0C3954' }}
                >
                  {moment().format('MMM')}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: 16,
                    color: '#0C3954',
                    mt: -1,
                  }}
                >
                  Entries
                </Typography>
              </Stack>
            </Stack>
            <Divider orientation="vertical" flexItem />
            <Stack
              direction={'row'}
              justifyContent={'center'}
              alignItems={'center'}
              sx={{ ml: -5 }}
              spacing={1}
            >
              <Typography
                sx={{ fontWeight: 600, fontSize: 36, color: '#0C3954' }}
              >
                {events.length}
              </Typography>
              <Stack direction={'column'} alignItems={'start'} sx={{}}>
                <Typography
                  sx={{ fontWeight: 600, fontSize: 16, color: '#0C3954' }}
                >
                  Total
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: 16,
                    color: '#0C3954',
                    mt: -1,
                  }}
                >
                  Entries
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Box>
        <JournalItems
          events={events}
          setEventDialogIsOpen={setEventDialogIsOpen}
          selectedEvents={selectedEvents}
        />
        {/* <TimelineHomeWidget setEventDialogIsOpen={setEventDialogIsOpen} /> */}
      </Stack>
      <CustomizedDialogs
        title={'Thank you! Our team will get back to you soon'}
        open={showSuccessAndContactDialog}
        setOpen={setShowSuccessAndContactDialog}
      ></CustomizedDialogs>
    </Container>
  );
};

export default Home;
