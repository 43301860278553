/* eslint-disable quotes */
import { Box, Button, Stack, Typography } from '@mui/material';
import Lottie from 'lottie-react';
import * as React from 'react';
import CustomizedDialogs from '../Dialog';

// eslint-disable-next-line react/prop-types
export default function CongratsDialogs({
  open,
  setOpen,
  type = 'firstEntry',
}) {
  const getTitle = (type = 'firstEntry') => {
    switch (type) {
      case 'firstEntry':
        return 'Congrats to Starting!';
      case 'entryComplited':
        return 'Great Job!';
      case '2Entries':
        return 'Two in a Row!';
      case '3Entries':
        return 'You’re on Fire!';
      case '10Entries':
        return 'Star Status Unlocked';
      case '25Entries':
        return 'Superstar Unlocked';
      case '50Entries':
        return 'Hero Unlocked';
      case '100Entries':
        return 'Superhero Unlocked';
      case '500Entries':
        return 'Legend Unlocked';
      case 'trackerCompleted':
        return 'Great Job!';
      default:
        break;
    }
  };

  const getSubtitle = (type = 'firstEntry') => {
    switch (type) {
      case 'firstEntry':
        return '1st Journal Entry';
      case 'entryComplited':
        return 'Entry Completed';
      case '2Entries':
        return '2x Streak';
      case '3Entries':
        return '3x Streak';
      case '10Entries':
        return '10 Entries!';
      case '25Entries':
        return '25 Entries!';
      case '50Entries':
        return '50 Entries!';
      case '100Entries':
        return '100 Entries!';
      case '500Entries':
        return '500 Entries!';
      case 'trackerCompleted':
        return 'Tracker Completed';
      default:
        break;
    }
  };

  const getLottie = (type = 'firstEntry') => {
    switch (type) {
      case 'firstEntry':
        return require('../../assets/lotties/JournalModalGreatJob.json');
      case 'entryComplited':
        return require('../../assets/lotties/JournalModalStreak1.json');
      case '2Entries':
        return require('../../assets/lotties/JournalModalStreak2.json');
      case '3Entries':
        return require('../../assets/lotties/JournalModalStreak3.json');
      case '10Entries':
        return require('../../assets/lotties/JournalModalStar.json');
      case '25Entries':
        return require('../../assets/lotties/JournalModalSuperstar.json');
      case '50Entries':
        return require('../../assets/lotties/JournalModalHero.json');
      case '100Entries':
        return require('../../assets/lotties/JournalModalSuperhero.json');
      case '500Entries':
        return require('../../assets/lotties/JournalModalLegend.json');
      case 'trackerCompleted':
        return require('../../assets/lotties/TrackerModalGreatJob.json');
      default:
        break;
    }
  };

  const getText = (type = 'firstEntry') => {
    switch (type) {
      case 'firstEntry':
        return "Here's to the start of a wonderful journey of self-discovery!";
      case 'entryComplited':
        return 'Each journal entry brings you a step closer to understanding your health better';
      case '2Entries':
        return "You're on a roll! Keep up the great work on your self-discovery journey!";
      case '3Entries':
        return "Wow what an impressive streak! Three days in a row shows you're making daily wellness a priority";
      case '10Entries':
        return 'Congrats, you’ve reached a new milestone! Your commitment to journaling is shining through!';
      case '25Entries':
        return 'Your crushing it! Your commitment to journaling is truly amazing, keep it up!';
      case '50Entries':
        return 'You’ve hit the big 5-0! Your journaling consistency is truly remarkable!';
      case '100Entries':
        return 'Wow you’re a health journal super hero and your dedication to your self-help is truly heroic!';
      case '500Entries':
        return "You've reached a whopping 500 entries - your dedication is an inspiration to us all!";
      case 'trackerCompleted':
        return 'Each tracker you take brings you a step closer to understanding your health better';
      default:
        break;
    }
  };

  return (
    <CustomizedDialogs open={open} setOpen={setOpen} fullScreen>
      <Stack
        direction={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        sx={{ width: '100%', minHeight: '100%' }}
        spacing={4}
      >
        <Box></Box>
        <Stack
          direction={'column'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Typography sx={{ fontSize: 24, fontWeight: 600, color: '#0C3954' }}>
            {getTitle(type)}
          </Typography>
          <Typography sx={{ fontSize: 24, fontWeight: 600, color: '#69B3E3' }}>
            {getSubtitle(type)}
          </Typography>
        </Stack>
        <Box
          sx={{
            width: '70%',
            aspectRatio: '1',
            bgcolor: '#D1E8F6',
            borderRadius: 999,
            position: 'relative',
          }}
        >
          <Lottie animationData={getLottie()} style={{ height: '100%' }} loop />
        </Box>
        <Typography
          sx={{
            fontSize: 20,
            fontWeight: 500,
            color: '#0C3954',
            textAlign: 'center',
            width: '85%',
          }}
        >
          {getText(type)}
        </Typography>
        {/* <Lottie
          animationData={require('../../assets/lotties/JournalModalFireworks.json')}
          style={{
            height: '100%',
            width: '100%',
            position: 'fixed',
            zIndex: -1,
            left: 0,
            top: 0,
          }}
          loop={false}
        /> */}
        <Box flex={1} />
        <Stack
          direction={'column'}
          gap={2}
          sx={{ width: '100%', position: 'relative', pb: 4 }}
        >
          <Button
            variant="contained"
            fullWidth
            size="large"
            sx={{
              bgcolor: 'rgb(26,56,82)',
              borderRadius: 30,
              fontSize: 18,
              height: 52,
              color: 'white',
              fontWeight: 600,
              textTransform: 'capitalize',
            }}
            onClick={() => setOpen(false)}
          >
            Close
          </Button>
          <Button
            variant="contained"
            fullWidth
            size="large"
            sx={{
              bgcolor: '#69B3E3',
              borderRadius: 30,
              fontSize: 18,
              height: 52,
              color: 'white',
              fontWeight: 600,
              textTransform: 'capitalize',
            }}
            onClick={() => setOpen(false)}
            id="step-5"
          >
            Journal Again
          </Button>
        </Stack>
      </Stack>
    </CustomizedDialogs>
  );
}
