import { AccessibleForwardRounded } from '@mui/icons-material';
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Button,
  Container,
  Drawer,
  Stack,
  Typography,
} from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Lottie from 'lottie-react';
import React, { useEffect, useState } from 'react';
import Joyride from 'react-joyride';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/Auth';
import { EventsContext } from '../contexts/EventsContext';
import { ProfileContext } from '../contexts/Profile';
import { WalkthroughContext } from '../contexts/Walkthrough';
import useResponsive from '../hooks/useResponsive';
import { ShowOnScroll } from '../screens/Home';
import { track } from '../utils/analytics';
import DashboardAppBar from './DashboardAppBar';
import SelectLanguage from './SelectLanguage';
import AnalyticsIcon from './icons/AnalyticsIcon';
import CheckInIcon from './icons/CheckInIcon';
import HomeIcon from './icons/HomeIcon';
import ProfileIcon from './icons/ProfileIcon';

const drawerWidth = 248;

const BACKGROUND = {
  width: '100%',
  minHeight: '100vh',
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  pb: 8,
};

const DashboardLayout = (props) => {
  const { handleJoyrideCallback, stepIndex, run, steps } =
    React.useContext(WalkthroughContext);
  const [showAccountSidebar, setShowAccountSidebar] = useState(false);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const { logout } = React.useContext(AuthContext);
  const { currentProfile, role } = React.useContext(ProfileContext);
  const { fetchEvents, openEventDialog } = React.useContext(EventsContext);

  const location = useLocation();
  const navigate = useNavigate();
  const isDesktop = useResponsive('up', 'md');

  useEffect(() => {
    if (currentProfile) {
      setShowAccountSidebar(location.pathname.includes('calender'));
      fetchEvents(currentProfile.id);
    }
  }, [location, currentProfile]);

  const getIndexValue = () => {
    if (location.pathname.includes('home')) return 0;
    if (location.pathname.includes('surveys')) return 1;
    if (location.pathname.includes('journal')) return 1;
    if (location.pathname.includes('analytics')) return 2;
    if (location.pathname.includes('profile')) return 3;
    return 0;
  };

  const isTabActive = (index) => {
    if (location.pathname.includes('home') && index === 0) return true;
    if (location.pathname.includes('surveys') && index === 1) return true;
    if (location.pathname.includes('analytics') && index === 2) return true;
    if (location.pathname.includes('profile') && index === 3) return true;
    return false;
  };

  const getPageHeaderButton = () => {
    if (location.pathname.includes('home')) return <></>;
    if (location.pathname.includes('journal'))
      return (
        <Button
          variant="filled"
          sx={{
            bgcolor: 'black',
            color: 'white',
            width: 'fit-content',
            height: { xs: 38, md: 52 },
            pl: 3,
            pr: 3,
            borderRadius: 4,
          }}
          onClick={() => {
            openEventDialog();
            track('add_journal_button_click');
          }}
        >
          Journal Now
        </Button>
      );
    if (location.pathname.includes('chat'))
      return (
        <Button
          variant="filled"
          sx={{
            bgcolor: 'black',
            color: 'white',
            width: 'fit-content',
            height: { xs: 38, md: 52 },
            pl: 3,
            pr: 3,
            borderRadius: 4,
          }}
          onClick={() => {
            track('chat-started', { from: 'dashboard' });
          }}
        >
          Chat Now
        </Button>
      );
    if (location.pathname.includes('learn')) return <></>;
    if (location.pathname.includes('profile')) return <></>;
    return <></>;
  };

  const onMenuSelect = (action) => {
    if (action === 'Logout') {
      track('menu_logout_button_click');
      logout();
    } else if (action === 'Legal') {
      track('menu_legal_button_click');
      // setUrl('https://knowrare.com/legal-app');
      window.open('https://knowrare.com/terms', '_blank');
    } else if (action === 'Edit Profile') {
      track('menu_edit_profile_button_click');
      navigate('/dashboard/profile');
    }
  };

  const Tooltip = ({
    continuous,
    index,
    step,
    backProps,
    closeProps,
    primaryProps,
    tooltipProps,
  }) => (
    <Stack {...tooltipProps} sx={{ bgcolor: 'white', borderRadius: 3 }}>
      <Box>{step.content}</Box>
      <Box>
        {/* {index > 0 && (
          <Button {...backProps}>
            <FormattedMessage id="back" />
          </Button>
        )} */}
        {continuous && (
          <Button {...primaryProps}>
            {/* <FormattedMessage id="next" /> */}
          </Button>
        )}
        {!continuous && (
          <Button {...closeProps}>
            {/* <FormattedMessage id="close" /> */}
          </Button>
        )}
      </Box>
    </Stack>
  );

  useEffect(() => {
    if (stepIndex === 7 || stepIndex === 8) {
      navigate(steps[stepIndex].data.next);
    }
  }, [stepIndex]);

  return (
    <Container sx={BACKGROUND} maxWidth={false} disableGutters>
      {role === 'viewer' && (
        <Box
          sx={{
            width: '100%',
            height: 30,
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 99999,
            fontSize: 9,
          }}
        >
          View Only Mode
        </Box>
      )}
      <Joyride
        // tooltipComponent={Tooltip}
        callback={handleJoyrideCallback}
        continuous
        run={run}
        scrollToFirstStep
        showProgress
        showSkipButton
        stepIndex={stepIndex}
        steps={steps}
        styles={{
          options: {
            zIndex: 999999999,
          },
        }}
      />
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'start',
          flex: 1,
        }}
      >
        <DashboardAppBar
          setOpenDrawer={setOpenDrawer}
          withLogo={false}
          getPageHeaderButton={getPageHeaderButton}
        />
        <Drawer
          variant={isDesktop ? 'permanent' : 'temporary'}
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            ['& .MuiDrawer-paper']: {
              width: drawerWidth,
              boxSizing: 'border-box',
              bgcolor: '#F4F5FB',
              border: 0,
            },
          }}
        >
          <Toolbar sx={{ mb: 2, mt: -0.3, pl: 2, pr: 2 }} disableGutters>
            <img
              src={require('../assets/images/coloredLogo.png')}
              height={54}
              alt=""
              style={{ cursor: 'pointer' }}
            />
          </Toolbar>
          <Box sx={{ overflow: 'auto' }}>
            <List sx={{ p: 2 }}>
              {(role === 'viewer'
                ? ['Home', 'Analytics']
                : ['Home', 'Surveys', 'Analytics', 'Profile']
              ).map((text, index) => (
                <ListItem
                  key={text}
                  disablePadding
                  onClick={() => {
                    if (index === 0) {
                      navigate('/dashboard/home');
                      track('navigation_home_button_click');
                    }
                    if (index === 1) {
                      navigate('/dashboard/surveys');
                      track('navigation_surveys_button_click');
                    }
                    if (index === 2) {
                      navigate('/dashboard/analytics');
                      track('navigation_analytics_button_click');
                    }
                    if (index === 3) {
                      navigate('/dashboard/profile');
                      track('navigation_profile_button_click');
                    }
                  }}
                  sx={{
                    bgcolor: isTabActive(index) ? '#C3E1F4' : null,
                    borderRadius: 1,
                    mb: 1,
                  }}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      {index === 0 && <HomeIcon id="step-6" />}
                      {index === 1 && <CheckInIcon id="step-7" />}
                      {index === 2 && <AnalyticsIcon id="step-8" />}
                      {index === 3 && <ProfileIcon />}
                    </ListItemIcon>
                    <ListItemText
                      primary={text}
                      primaryTypographyProps={{ fontWeight: 600, ml: -2 }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <Box flex={1} />
            <List sx={{ mt: 2 }}>
              <ListItem>
                <Button
                  startIcon={<AccessibleForwardRounded />}
                  LinkComponent={'a'}
                  href="#"
                  data-acsb-custom-trigger="true"
                  sx={{ color: '#161C24', fontWeight: 400 }}
                >
                  Accessability
                </Button>
              </ListItem>
              <ListItem onClick={() => onMenuSelect(setting)}>
                <SelectLanguage />
              </ListItem>
              <ListItem>
                <Typography
                  textAlign="center"
                  sx={{ fontSize: 12, pl: 1, color: '#929292' }}
                >
                  © 2023 Know Healthtech Inc
                </Typography>
              </ListItem>
            </List>
          </Box>
        </Drawer>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            justifyContent: 'start',
            alignItems: 'start',
            p: 0,
            width: '100%',
          }}
        >
          <Toolbar />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              justifyContent: 'start',
              alignItems: 'start',
              p: isDesktop ? 0 : 0,
              width: '100%',
            }}
          >
            <Outlet />
          </Box>
          {!isDesktop && (
            <Box sx={{ position: 'relative' }}>
              <BottomNavigation
                showLabels
                sx={{ position: 'fixed', bottom: 0, width: '100%', height: 72 }}
                value={getIndexValue()}
                onChange={(_, newValue) => {
                  console.log('newValue', newValue);
                  if (role === 'viewer') {
                    if (newValue === 0) {
                      navigate('/dashboard/home');
                      track('navigation_home_button_click');
                    }
                    if (newValue === 2) {
                      navigate('/dashboard/analytics');
                      track('navigation_analytics_button_click');
                    }
                  } else {
                    if (newValue === 0) {
                      navigate('/dashboard/home');
                      track('navigation_home_button_click');
                    }
                    if (newValue === 1) {
                      navigate('/dashboard/surveys');
                      track('navigation_surveys_button_click');
                    }
                    if (newValue === 2) {
                      navigate('/dashboard/analytics');
                      track('navigation_analytics_button_click');
                    }
                    if (newValue === 3) {
                      navigate('/dashboard/profile');
                      track('navigation_profile_button_click');
                    }
                  }
                }}
              >
                <BottomNavigationAction label="Home" icon={<HomeIcon />} />
                {role !== 'viewer' && (
                  <BottomNavigationAction
                    label="Surveys"
                    icon={<CheckInIcon />}
                    id="step-7"
                  />
                )}
                <BottomNavigationAction
                  label="Analytics"
                  icon={<AnalyticsIcon />}
                  id="step-8"
                />
                {role !== 'viewer' && (
                  <BottomNavigationAction
                    label="Profile"
                    icon={<ProfileIcon />}
                  />
                )}
                {(getIndexValue() === 0 || getIndexValue() === 1) &&
                  role !== 'viewer' && (
                    <ShowOnScroll {...props}>
                      <Box
                        sx={{
                          position: 'absolute',
                          left: 'calc(50% - 36px)',
                          top: '-36px',
                          transform: 'translate(-50%, -50%)',
                          zIndex: 999999999999999999999999999999999999,
                        }}
                        onClick={() => openEventDialog()}
                      >
                        <Lottie
                          animationData={
                            getIndexValue() === 0
                              ? require('../assets/lotties/loadingBlueButton.json')
                              : require('../assets/lotties/loadingDarkBlueButton.json')
                          }
                          loop={true}
                          width={72}
                          height={72}
                          style={{ width: 72, height: 72 }}
                        />
                      </Box>
                    </ShowOnScroll>
                  )}
              </BottomNavigation>
            </Box>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default DashboardLayout;
