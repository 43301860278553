import { Box, Button, Card, Stack, Typography } from '@mui/material';
import { PopupButton } from '@typeform/embed-react';
import Lottie from 'lottie-react';
import React, { useContext, useEffect, useState } from 'react';
import preSurveyDialogEmotional from '../assets/lotties/preSurveyDialogEmotional.json';
import preSurveyDialogFatigue from '../assets/lotties/preSurveyDialogFatigue.json';
import { EventsContext } from '../contexts/EventsContext';
import { ProfileContext } from '../contexts/Profile';
import useResponsive from '../hooks/useResponsive';
import { track } from '../utils/analytics';
import { ASSESSMENTS, ASSESSMENTS_IDS, EVENTS } from './AddOrEditEventDialog';
import CustomizedDialogs from './Dialog';
import Loader from './Loader';

const DIALOG_CONTENT = {
  fontSize: 20,
  fontWeight: 500,
  color: '#0C3954',
  textAlign: 'center',
  width: '100%',
};

const DIALOG_BUTTON = {
  bgcolor: '#69B3E3',
  borderRadius: 30,
  fontSize: 18,
  position: 'relative',
  zIndex: 9999999999,
  height: 52,
  width: '100%',
  color: 'white',
  fontWeight: 600,
  textTransform: 'capitalize',
};

const DIALOG_FOOTER_CONTAINER = {
  position: 'fixed',
  bottom: 0,
  left: 0,
  width: '100%',
  p: 2,
  boxShadow: '0px -24px 32px #fff',
  bgcolor: 'white',
  zIndex: 9999,
};

const DIALOG_TITLE = {
  fontSize: 24,
  fontWeight: 600,
  color: '#0C3954',
  textAlign: 'center',
};

const DIALOG_LOTTIE_CONTAINER = {
  width: 220,
  height: 220,
  minHeight: 220,
  aspectRatio: '1',
  bgcolor: '#D1E8F6',
  borderRadius: 999,
  position: 'relative',
};

const getSurveyScore = (event) => {
  if (!event) return 0;
  if (ASSESSMENTS_IDS.includes(event.data().formId)) {
    return event?.data()?.response.form_response.calculated.score;
  }
};

const getTotalScoreForAssesment = (event) => {
  let SEARCH_SOURCE = EVENTS;
  if (ASSESSMENTS_IDS.includes(event.data().formId))
    SEARCH_SOURCE = ASSESSMENTS;
  for (let index = 0; index < SEARCH_SOURCE.length; index++) {
    const e = SEARCH_SOURCE[index];
    if (e.id === event.data().formId) {
      return e.totalScore;
    }
  }
};

const PreSurveyDialog = ({
  surveyData,
  currentProfile,
  open,
  setOpen,
  onSubmit,
}) => {
  const isDesktop = useResponsive('up', 'md');
  const data = {
    fatigue: {
      title: 'Fatigue Survey',
      lottie: preSurveyDialogFatigue,
      content: [
        'Fatigue is hard to describe or measure, yet it can keep you from participating in daily life. Answer the questions here about how much your fatigue has affected your life in the past 7 days.',
        'Consistently taking the fatigue survey helps your doctor grasp its impact on your life over time.',
        'The lower the score, the more that fatigue is having an impact.',
      ],
    },
    emotionalHealth: {
      title: 'Emotional Health Survey',
      lottie: preSurveyDialogEmotional,
      content: [
        'When you find life to be overwhelming and you are not enjoying things as much as you used to, it may be time to talk about how you’re feeling with your doctor.',
        'This survey will help you identify how much your feelings and emotions may be interfering with your enjoyment of life. This will help you recognize that it may be time to seek help and for your doctor, it will help them understand how much your mood may be interfering with your life.',
        'The higher the score, the more your emotional health is having an impact on your life. ',
      ],
    },
  };
  return (
    <CustomizedDialogs
      open={open}
      setOpen={setOpen}
      fullScreen={isDesktop ? false : true}
    >
      {surveyData.type && (
        <Stack
          direction={'column'}
          justifyContent={'start'}
          alignItems={'center'}
          sx={{
            width: '100%',
            height: '100%',
          }}
          spacing={6}
        >
          <Box></Box>
          <Stack
            direction={'column'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Typography sx={DIALOG_TITLE}>
              {data[surveyData.type].title}
            </Typography>
          </Stack>
          <Box sx={DIALOG_LOTTIE_CONTAINER}>
            <Lottie
              animationData={data[surveyData.type].lottie}
              style={{ height: '100%' }}
              loop
            />
          </Box>
          <Stack
            direction={'column'}
            justifyContent={'start'}
            alignItems={'center'}
            sx={{
              width: '100%',
              pb: 14,
            }}
            spacing={3}
          >
            {data[surveyData.type].content.map((content, index) => (
              <Typography sx={DIALOG_CONTENT} key={index}>
                {content}
              </Typography>
            ))}
          </Stack>
          {/* <Box flex={1} /> */}
          <Box sx={DIALOG_FOOTER_CONTAINER}>
            <PopupButton
              key={surveyData.id}
              id={surveyData.id}
              size={60}
              hidden={{
                userId: currentProfile?.id,
                type: surveyData.type,
              }}
              onReady={() => {
                console.log('form ready');
                track('open_survey_form_button_click', { surveyData });
                track('journal-started', { surveyData });
              }}
              onSubmit={() => onSubmit()}
              style={{
                border: 'none',
                background: 'transparent',
                width: '100%',
              }}
            >
              <Button
                variant="contained"
                fullWidth
                size="large"
                sx={DIALOG_BUTTON}
              >
                Start Survey
              </Button>
            </PopupButton>
          </Box>
        </Stack>
      )}
    </CustomizedDialogs>
  );
};

export const PostSurveyDialog = ({ open, setOpen, event }) => {
  const isDesktop = useResponsive('up', 'md');
  const indicationMapping = (score) => {
    if (score > 0 && score <= 4) {
      return 'Minimal Depression';
    } else if (score >= 5 && score <= 9) {
      return 'Mild Depression';
    } else if (score >= 10 && score <= 14) {
      return 'Moderate Depression';
    } else if (score >= 15 && score <= 19) {
      return 'Moderate Severe Depression';
    } else if (score >= 20) {
      return 'Severe Depression';
    }
  };
  const data = {
    // Emotional
    C7NnGZyA: {
      title: 'Thanks for taking the Emotional Health Survey, you scored',
      content: [
        <span key={0}>
          <b>
            Your score indicates your feelings are a sign of{' '}
            <span style={{ color: '#0C3954' }}>
              {indicationMapping(getSurveyScore(event))}
            </span>
          </b>
        </span>,
        <span key={1}>
          It’s important to share your survey results with a healthcare
          professional who can point you in the right direction to next steps
          that can help with your emotional health.
        </span>,
        <span key={2}>
          After taking this survey at least <b>3 times in a month</b>, you can
          show an <b>AI summary</b> of your scores to your doctor from our
          reporting section.
        </span>,
      ],
    },
    // Fatigue
    FMkEt9ok: {
      title: 'Thanks for taking the Fatigue Survey, you scored',
      content: [
        <span key={0}>
          <b>The lowest the score the lower the fatigue is affecting you.</b>
        </span>,
        <span key={1}>
          Take this several times a month to share with your doctor and{' '}
          <b>validate</b> how much fatigue is affecting you.
        </span>,
        <span key={2}>
          Consider discussing with your doctor how they would like you to take
          and repeat this survey
        </span>,
        <span key={3}>
          After taking this survey at least <b>3 times in a month</b>, you can
          show an <b>AI summary</b> of your scores to your doctor from our
          reporting section.
        </span>,
      ],
    },
  };
  return (
    <CustomizedDialogs
      open={open}
      setOpen={setOpen}
      fullScreen={isDesktop ? false : true}
    >
      {event && (
        <Stack
          direction={'column'}
          justifyContent={'start'}
          alignItems={'center'}
          sx={{
            width: '100%',
            height: '100%',
          }}
          spacing={6}
        >
          <Stack
            direction={'column'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Typography sx={DIALOG_TITLE}>
              {`${data[event.data().formId]?.title} ${getSurveyScore(
                event,
              )}/${getTotalScoreForAssesment(event)}`}
            </Typography>
          </Stack>
          <Box sx={DIALOG_LOTTIE_CONTAINER}>
            <Typography
              sx={{
                fontSize: 64,
                fontWeight: 700,
                textAlign: 'center',
                height: 220,
                width: 220,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {event && getSurveyScore(event)}
            </Typography>
          </Box>
          <Stack
            direction={'column'}
            justifyContent={'start'}
            alignItems={'center'}
            sx={{
              width: '100%',
              pb: 14,
            }}
            spacing={3}
          >
            {(data[event.data().formId]?.content || []).map(
              (content, index) => (
                <Typography sx={DIALOG_CONTENT} key={index}>
                  {content}
                </Typography>
              ),
            )}
          </Stack>
          <Box sx={DIALOG_FOOTER_CONTAINER}>
            <Button
              variant="contained"
              fullWidth
              size="large"
              sx={DIALOG_BUTTON}
              onClick={() => setOpen(false)}
            >
              Close
            </Button>
          </Box>
        </Stack>
      )}
    </CustomizedDialogs>
  );
};

const SurveyCard = ({
  title,
  subtitle,
  image,
  bgcolor = 'primary.main',
  surveyData,
}) => {
  useEffect(() => {}, []);
  const isDesktop = useResponsive('up', 'md');
  const { currentProfile } = useContext(ProfileContext);
  const { fetchEvents, events } = React.useContext(EventsContext);
  const [openPreSurveyDialog, setOpenPreSurveyDialog] = useState(false);
  const [openPostSurveyDialog, setOpenPostSurveyDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    setLoading(true);
    setOpenPreSurveyDialog(false);
    setTimeout(() => {
      fetchEvents(currentProfile.id);
      track('journal-submission', { surveyData });
      setLoading(false);
      setOpenPostSurveyDialog(true);
    }, 3000);
  };

  return (
    <>
      {loading && <Loader />}
      <PreSurveyDialog
        surveyData={surveyData}
        currentProfile={currentProfile}
        open={openPreSurveyDialog}
        setOpen={setOpenPreSurveyDialog}
        onSubmit={onSubmit}
      />
      <PostSurveyDialog
        open={openPostSurveyDialog}
        setOpen={setOpenPostSurveyDialog}
        event={(events && events.length && events[0]) || null}
      />
      <Card
        sx={{
          height: 'fit-content',
          width: isDesktop ? 'fit-content' : '70vw',
          borderRadius: 6,
          p: 2,
          bgcolor: bgcolor,
          mt: 2,
          position: 'relative',
          cursor: 'pointer',
        }}
        elevation={4}
        onClick={() => setOpenPreSurveyDialog(true)}
      >
        <Stack
          direction={isDesktop ? 'column' : 'column'}
          spacing={4}
          alignItems={'center'}
          justifyContent={'center'}
        >
          {/* <img
            alt=""
            src={image}
            style={{
              height: 200,
              width: 200,
              objectFit: 'cover',
              borderRadius: 8,
            }}
          /> */}
          <Box
            sx={{
              ...DIALOG_LOTTIE_CONTAINER,
              width: 140,
              height: 140,
              maxWidth: 140,
              maxHeight: 140,
              minWidth: 140,
              minHeight: 140,
            }}
          >
            <Lottie
              animationData={image}
              style={{
                height: title === 'Fatigue' ? '200%' : '100%',
                width: title === 'Fatigue' ? '200%' : '100%',
                marginTop: title === 'Fatigue' ? '-68px' : null,
                marginLeft: title === 'Fatigue' ? '-68px' : null,
              }}
              loop
            />
          </Box>
          <Stack direction={'column'} spacing={1} alignItems={'center'}>
            <Typography
              sx={{
                textAlign: 'center',
                color: '#0B3954',
                fontSize: 24,
                fontWeight: 600,
              }}
            >
              {title}
            </Typography>
            <Typography
              sx={{
                textAlign: 'center',
                color: '#0B3954',
                fontSize: 15,
                fontWeight: 400,
              }}
            >
              {subtitle}
            </Typography>
            <div></div>
            <Button
              variant="contained"
              sx={{ bgcolor: '#0C3954', borderRadius: 3 }}
              onClick={() => setOpenPreSurveyDialog(true)}
            >
              Start Now
            </Button>
          </Stack>
        </Stack>
      </Card>
    </>
  );
};

export default SurveyCard;
