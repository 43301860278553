import { Container, Grid, Stack, Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import preSurveyDialogEmotional from '../assets/lotties/preSurveyDialogEmotional.json';
import preSurveyDialogFatigue from '../assets/lotties/preSurveyDialogFatigue.json';
import { ASSESSMENTS } from '../components/AddOrEditEventDialog';
import SurveyCard from '../components/SurveyCard';
import SurveysHomeWidget from '../components/SurveysHomeWidget';
import { EventsContext } from '../contexts/EventsContext';
import { ProfileContext } from '../contexts/Profile';
import useResponsive from '../hooks/useResponsive';

const CheckIn = () => {
  useEffect(() => {}, []);
  const isDesktop = useResponsive('up', 'md');

  const { currentProfile } = useContext(ProfileContext);
  const { events, eventDialogIsOpen, setEventDialogIsOpen, openEventDialog } =
    useContext(EventsContext);

  return (
    <Container disableGutters maxWidth={false} sx={{ mb: 3 }}>
      <Stack
        direction={'column'}
        alignItems={'start'}
        spacing={2}
        sx={{ height: '100%', pt: isDesktop ? 0 : 3 }}
      >
        <Typography
          sx={{
            color: 'black',
            fontSize: 24,
            fontWeight: 600,
            textAlign: 'center',
            width: '100%',
            marginTop: 72,
            pl: 8,
            pr: 8,
          }}
        >{`${
          currentProfile.data().name.split(' ')[0]
        }, take a survey to discover how your condition is affecting you`}</Typography>
        <Typography
          sx={{
            color: 'black',
            fontSize: 16,
            fontWeight: 400,
            textAlign: 'center',
            width: '100%',
            marginTop: 72,
            pl: 2,
            pr: 2,
          }}
        >
          Taking these surveys developed by doctors to help validate and measure
          how your disease affects your physical, emotional, and social
          well-being daily
        </Typography>
        {isDesktop ? (
          <Grid
            container
            spacing={4}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Grid item sm={12} md={3} xl={3} xs={12}>
              <SurveyCard
                surveyData={ASSESSMENTS[0]}
                title={'Emotional Health'}
                subtitle={'Measure how much emotions are interfering with life'}
                image={preSurveyDialogEmotional}
                bgcolor={'#FEF1AB'}
              />
            </Grid>
            <Grid item sm={12} md={3} xl={3} xs={12}>
              <SurveyCard
                surveyData={ASSESSMENTS[1]}
                formId="FMkEt9ok"
                title={'Fatigue'}
                subtitle={'Measure the impact of fatigue on daily life'}
                image={preSurveyDialogFatigue}
                bgcolor={'#CFF5E3'}
              />
            </Grid>
          </Grid>
        ) : (
          <SurveysHomeWidget />
        )}
      </Stack>
    </Container>
  );
};

export default CheckIn;
